<template lang="pug">
.record
  v-container
    v-row.mt-10
      v-col(cols="12", md="4")
        h5.normal_title_h5(v-if="seasonName") 本季戰績
      v-col(cols="6", sm="3", md="2")
        select.selector(
          name="gameInquiry",
          v-model="gameInquiryType",
          @change="gameInquiryChange"
        )
          option(:value="index", v-for="(item, index) in gameList") {{ item }}
      v-col(cols="6", sm="3", md="2")
        select.selector(
          name="opponentInquiry",
          v-model="opponentInquiryType",
          @change="opponentInquiryChange"
        )
          option(:value="index", v-for="(item, index) in opponentInquiryList") {{ item }}
      v-col(cols="6", sm="3", md="2")
        select.selector(
          name="eventInquiry",
          v-model="eventInquiryType",
          @change="eventInquiryChange"
        )
          option(:value="index", v-for="(item, index) in eventInquiryList") {{ item }}
      v-col(cols="6", sm="3", md="2")
        select.selector(
          name="winLoseInquiry",
          v-model="winLoseInquiryType",
          @change="winLoseInquiryChange"
        )
          option(:value="index", v-for="(item, index) in winLoseInquiryList") {{ item }}
    v-row.table_row
      v-col#sticky-nav.table_col.table.position-relative.mt-5(cols="12")
        table
          thead
            tr.bottom_head
              th.bdr(
                v-for="(title, index) in seasonHeadTitleList",
                :key="index",
                :class="{ bdr: index != seasonHeadTitleList.length - 1 }"
              ) {{ title }}
          tbody
            tr.hover(
              v-for="(teamGame, index) in currentTeamGameList",
              :key="index",
              style="cursor: pointer",
              @click="gameClick(teamGame)"
            )
              td.bdl.bdr {{ teamGame.category }}
              td.bdr {{ teamGame.name }}
              td.bdr {{ formatDate(teamGame.date) }}
              td.bdr
                .opponent_container.d-flex.align-center.justify-center
                  img.temp_logo_hidden(
                    width="30px",
                    v-if="teamGame.opponent_photo",
                    :src="teamGame.opponent_photo.image"
                  )
                  span.ml-1 {{ teamGame.opp_team_name }}
              td.bdr {{ teamGame.is_home ? '主' : '客' }}
              td.bdr(
                :class="[teamGame.is_win ? 'text_secondary' : 'text_light_gray']"
              ) {{ teamGame.is_win ? '勝' : '負' }}
              td {{ getScore(teamGame) }}
  v-container
    v-row.mt-10
      v-col(cols="12")
        h5.normal_title_h5 歷年戰績
    v-row.table_row
      v-col.table_col.table.position-relative(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 120px; min-width: 120px",
                :title="historyHeadTitleList[0]",
                :offset="0"
              )
              th.bdr(
                v-if="index > 0",
                v-for="(title, index) in historyHeadTitleList",
                :key="index",
                :class="{ bdr: index != seasonHeadTitleList.length - 1 }"
              ) {{ title }}
          tbody
            tr(v-for="(teamSeason, index) in teamSeasonData", :key="index")
              sticky-column.bdl.bdr2(
                style="width: 120px; min-width: 120px",
                :offset="0",
                :title="teamSeason.info.season_name"
              )
              td.bdr {{ getRankText(teamSeason.ranking) }}
              td.bdr {{ teamSeason.info.record_matches }}
              td.bdr {{ teamSeason.info.wins }}
              td.bdr {{ teamSeason.info.losses }}
              td.bdr {{ (teamSeason.info.win_rate * 100).toFixed(1) }}%
              td.bdr {{ (teamSeason.info.home_win_rate * 100).toFixed(1) }}%
              td {{ (teamSeason.info.away_win_rate * 100).toFixed(1) }}%
</template>

<script>
import { getTeamSeasonData } from "@/api/team";
import { getGameList } from "@/api/game";
import moment from "moment";
import StickyColumn from "@/components/common/Table/StickyColumn";
import { get } from "@/utils/util";

export default {
  name: "LeagueTeamRecords",
  components: {
    StickyColumn,
  },
  data() {
    return {
      league: this.$route.params.league,
      season: this.$route.params.season,
      teamID: this.$route.params.id,
      seasonInquiryType: 0,
      seasonTeamId: 0,
      seasonName: undefined,
      teamGameList: [],
      currentTeamGameList: [],
      teamSeasonData: [],

      gameInquiryType: 0,
      eventInquiryType: 0,
      opponentInquiryType: 0,
      winLoseInquiryType: 0,
      gameList: [],
      eventInquiryList: [],
      opponentInquiryList: [],
      winLoseInquiryList: [],

      historyHeadTitleList: [
        "球季",
        "名次",
        "場次",
        "勝",
        "負",
        "勝率",
        "主場勝率",
        "客場勝率",
      ],

      seasonHeadTitleList: [
        "賽別",
        "場次",
        "日期",
        "對手",
        "主/客",
        "勝負",
        "比分",
      ],
    };
  },
  watch: {
    "$route.params.season"() {
      this.season = this.$route.params.season;

      this.teamGameList = [];
      this.currentTeamGameList = [];
      this.gameInquiryType = 0;
      this.eventInquiryType = 0;
      this.opponentInquiryType = 0;
      this.winLoseInquiryType = 0;
      this.gameList = [];
      this.eventInquiryList = [];
      this.opponentInquiryList = [];
      this.winLoseInquiryList = [];

      this.getSeasonTeamInfo();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getTeamSeasonData();
      await this.getSeasonTeamInfo();
    },
    async getTeamSeasonData() {
      let temp = {
        league_id: this.league,
        part: "info,ranking",
        team_id: this.teamID,
      };
      await getTeamSeasonData(temp).then((response) => {
        this.teamSeasonData = response.data;
      });
    },
    async getSeasonTeamInfo() {
      let temp = {
        season_id: this.season,
        part: "info",
        team_id: this.teamID,
      };
      await getTeamSeasonData(temp).then((response) => {
        this.seasonName = response.data.summary.info.season_name;
        this.seasonTeamId = response.data.summary.info.season_team_id;
        this.getTeamGameList();
      });
    },
    async getTeamGameList() {
      let temp = {
        show_all_page: true,
        season_id: this.season,
        group_by: "TEAM",
        season_team_id: this.seasonTeamId,
      };
      await getGameList(temp).then((response) => {
        this.teamGameList = response.data;
        this.currentTeamGameList = Object.assign([], this.teamGameList);
        this.getSplitList();
      });
    },
    getSplitList() {
      this.gameList = [];
      this.eventInquiryList = [];
      this.opponentInquiryList = [];
      this.winLoseInquiryList = [];
      this.gameList.push("賽別");
      this.eventInquiryList.push("主/客");
      this.eventInquiryList.push("主");
      this.eventInquiryList.push("客");
      this.opponentInquiryList.push("對手");
      this.winLoseInquiryList.push("勝/負");
      this.winLoseInquiryList.push("勝");
      this.winLoseInquiryList.push("負");
      for (let index in this.teamGameList) {
        let temp = this.teamGameList[index];
        if (!this.gameList.includes(temp.category)) {
          this.gameList.push(temp.category);
        }
        if (!this.opponentInquiryList.includes(temp.opp_team_name)) {
          this.opponentInquiryList.push(temp.opp_team_name);
        }
      }
    },
    gameInquiryChange() {
      this.eventInquiryType = 0;
      this.opponentInquiryType = 0;
      this.winLoseInquiryType = 0;
      this.getSplitData();
    },
    eventInquiryChange() {
      this.gameInquiryType = 0;
      this.opponentInquiryType = 0;
      this.winLoseInquiryType = 0;
      this.getSplitData();
    },
    opponentInquiryChange() {
      this.gameInquiryType = 0;
      this.eventInquiryType = 0;
      this.winLoseInquiryType = 0;
      this.getSplitData();
    },
    winLoseInquiryChange() {
      this.gameInquiryType = 0;
      this.eventInquiryType = 0;
      this.opponentInquiryType = 0;
      this.getSplitData();
    },
    getSplitData() {
      this.currentTeamGameList = [];
      if (this.gameInquiryType != 0) {
        let type = this.gameList[this.gameInquiryType];
        for (let index in this.teamGameList) {
          let temp = this.teamGameList[index];
          if (type == temp.category) {
            this.currentTeamGameList.push(temp);
          }
        }
      } else if (this.eventInquiryType != 0) {
        for (let index in this.teamGameList) {
          let temp = this.teamGameList[index];
          if (this.eventInquiryType == 1 && temp.is_home) {
            this.currentTeamGameList.push(temp);
          } else if (this.eventInquiryType == 2 && !temp.is_home) {
            this.currentTeamGameList.push(temp);
          }
        }
      } else if (this.opponentInquiryType != 0) {
        let type = this.opponentInquiryList[this.opponentInquiryType];
        for (let index in this.teamGameList) {
          let temp = this.teamGameList[index];
          if (type == temp.opp_team_name) {
            this.currentTeamGameList.push(temp);
          }
        }
      } else if (this.winLoseInquiryType != 0) {
        for (let index in this.teamGameList) {
          let temp = this.teamGameList[index];
          if (this.winLoseInquiryType == 1 && temp.is_win) {
            this.currentTeamGameList.push(temp);
          } else if (this.winLoseInquiryType == 2 && !temp.is_win) {
            this.currentTeamGameList.push(temp);
          }
        }
      } else {
        for (let index in this.teamGameList) {
          let temp = this.teamGameList[index];
          this.currentTeamGameList.push(temp);
        }
      }
    },
    getScore(teamGame) {
      return teamGame.home_team_pts + ":" + teamGame.away_team_pts;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    gameClick(game) {
      this.$router.push({
        name: "LeagueGame",
        params: {
          id: game.id,
        },
      });
    },
    getRankText(ranking) {
      let win_data = get(ranking, "win_rate", {});
      let rank = get(win_data, "ranking", null);
      if (rank == null) {
        return null;
      } else if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";

table {
  text-align: center;
}
</style>
